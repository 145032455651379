
.BoothAdvertisingTwo{
    //height: 100%;
    .headerStep{
        margin-top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        .stepOne{
            display: flex;
            flex-direction: column;
            align-items: center;
            .num{
                line-height: 1;
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 50%;
                background: #D1D2DB;
                color: #ffffff;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                line-height: 40px;
            }
            .numActive{
                background: #1122D8;
            }
            .text{
                line-height: 1;
                color: #878994;
                font-size: 14px;
                font-weight: 500;
                margin-top: 10px;
            }
        }
        .arrows{
            height: 10px;
            margin-bottom: 20px;
        }
    }
    .BoothAdvertisingTwoHeader{
        margin-top: 76px;
        ::v-deep .step-two-form{
            padding-left: 30px;
            .office-title {
                margin-bottom: 40px;
                .el-form-item__label {
                    font-size: 16px;
                    font-weight: 500;
                    color: #13131B;
                }
            }
            .form-title {
                margin-top: 20px;
                color: #1121D7;
                font-size: 16px;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-bottom: 12px;
                position: relative;
                font-weight: bold;
                .iconfont {
                    margin-right: 10px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 26px;
                    bottom: 0;
                    width: 760px;
                    border-bottom: 1px solid #DBDBDB;
                }
            }
            .form-title2{
                margin-top: 20px;
                color: #1121D7;
                font-size: 16px;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-bottom: 12px;
                position: relative;
                font-weight: bold;
                .iconfont {
                    margin-right: 10px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 26px;
                    bottom: 0;
                    width: calc(100% - 160px);
                    border-bottom: 1px solid #DBDBDB;
                }
            }
            .form-title3{
                margin-top: 20px;
                color: #1121D7;
                font-size: 16px;
                display: flex;
                align-items: center;
                line-height: 1;
                padding-bottom: 12px;
                position: relative;
                font-weight: bold;
                .iconfont {
                    margin-right: 10px;
                }
                &:after {
                    content: '';
                    position: absolute;
                    left: 26px;
                    bottom: 0;
                    width: calc(100% - 500px);
                    border-bottom: 1px solid #DBDBDB;
                }
            }
            .form-content{
                padding-left: 45px;
                & > .el-form-item {
                    margin-top: 20px;
                    margin-right: 110px;
                    width: 120px;
                }
                &.goods-info-wrapper{
                    display: flex;
                    .item {
                        .line-title {
                            line-height: 41px;
                            width: 70px;
                            margin-bottom: 22px;
                            text-align: right;
                            position: relative;
                            margin-right: 10px;
                            &.line-title-first {
                                width: 160px;
                                line-height: 1;
                                font-size: 16px;
                                color: #13131B;
                                height: 16px;
                                text-align: center;
                                margin: 30px 60px 20px 0;
                            }
                        }
                        &.item-line1 {
                            .line-title {
                                &:before {
                                    content: '*';
                                    color: #F56C6C;
                                    margin-right: 4px;
                                }
                            }
                        }
                    }
                }
            }
            .timeSet{
                //width: 758px;
              box-sizing: border-box;
                padding-left: 56px;
                .time-title{
                    color: #13131B;
                    font-size: 14px;
                    padding-top: 24px;
                    display: flex;
                    justify-content:space-around;
                    .titleSpan:nth-child(1){
                        margin-left: 16px;
                    }
                }

                .oneToSix{
                    margin-top: 18px;
                    .oneToSix-item1{
                        display: flex;
                        /*justify-content: space-between;*/
                        align-items: center;
                      ::v-deep .item-input{
                            width: 160px;
                        }
                        span:nth-child(1){
                            //padding-left: 32px;
                            width: 40px;
                            text-align: center;
                        }
                    }
                    .oneToSix-item2{
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        /*justify-content: space-between;*/
                        .item2-input{
                          ::v-deep .item-input{
                                width: 60px;
                                /*display: flex;*/
                            }
                        }
                    }
                }
            }
            .timeSet2{
                display: flex;
                padding-left: 36px;
                width: calc(100% - 130px);
                .timeSet2-left{
                    /*width: 72px;*/
                    padding-left: 10px;
                    padding-top: 60px;
                    display: flex;
                    flex-direction: column;
                    color: #14141C;
                    font-size: 14px;
                    span{
                        width: 70px;
                        display: inline-block;
                        text-align:right
                        //margin-left: -10px;
                    }
                    span:nth-child(1){
                        //margin-left: -2px;
                        padding-top: 10px;
                    }
                    span:nth-child(2){
                        padding-top: 42px;
                    }
                    span:nth-child(3){
                        padding-top: 42px;
                    }
                    span:nth-child(4){
                        //margin-left: -2px;
                        padding-top: 84px;
                    }
                    span:nth-child(5){
                        padding-top: 40px;
                    }
                    span:nth-child(6){
                        padding-top: 38px;
                    }
                    span:nth-child(7){
                        padding-top: 86px;
                    }
                    span:nth-child(8){
                        padding-top: 38px;
                    }
                    span:nth-child(9){
                        padding-top: 38px;
                    }
                    span:nth-child(10){
                        padding-top: 86px;
                    }
                    span:nth-child(11){
                        padding-top: 38px;
                    }
                    span:nth-child(12){
                        padding-top: 38px;
                    }
                    span:nth-child(13){
                        padding-top: 100px;
                    }
                    span:nth-child(14){
                        padding-top: 38px;
                    }
                    span:nth-child(15){
                        padding-top: 38px;
                    }
                    span:nth-child(16){
                        padding-top: 92px;
                    }
                    span:nth-child(17){
                        padding-top: 38px;
                    }
                    span:nth-child(18){
                        padding-top: 38px;
                    }
                }
                .timeSet2-right{
                    display: flex;
                    flex-wrap: wrap;
                    /*justify-content: space-around;*/
                    width: calc(100% - 70px);
                    padding-left: 0px;
                    .timeSet2-rightItem{
                        flex: 0 0 16.6%;
                        margin-top: 30px;
                        display: flex;
                        flex-direction: column;
                        text-align: center;
                        .rightItemInput{
                            width: 160px;
                            margin-top: 18px;
                        }
                    }
                }
            }
            .regionSet{
                //width: 758px;
              box-sizing: border-box;
              padding-left:40px;
                .time-title{
                    color: #13131B;
                    font-size: 14px;
                    padding-top: 24px;
                    display: flex;
                    justify-content:space-around;
                    .titleSpan:nth-child(1){
                        margin-left: 16px;
                    }
                }

                .oneToSix{
                    margin-top: 18px;
                    .oneToSix-item1{
                        display: flex;
                        /*justify-content: space-between;*/
                        align-items: center;
                      ::v-deep .item-input{
                            width: 160px;
                        }
                        span:nth-child(1){
                            width: 56px;
                            display: inline-block;
                        }
                    }
                    .oneToSix-item2{
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        /*justify-content: space-between;*/
                        .item2-input{
                          ::v-deep .item-input{
                                width: 60px;
                                /*display: flex;*/
                            }
                        }
                    }
                }
            }
            .crowdOrient{
              box-sizing: border-box;
              padding-left: 54px;
                .time-title{
                    color: #13131B;
                    font-size: 14px;
                    padding-top: 24px;
                    display: flex;
                    justify-content:space-around;
                    .titleSpan:nth-child(1){
                        margin-left: 16px;
                    }
                }
                .oneToSix{
                    margin-top: 18px;
                    .oneToSix-item1{
                        margin-top: 0;
                    }
                    .oneToSix-item1{
                        margin-top: 18px;
                        display: flex;
                        /*justify-content: space-between;*/
                        align-items: center;
                      ::v-deep .item-input{
                            width: 160px;
                        }
                        span:nth-child(1){
                            /*width: 56px;*/
                            display: inline-block;
                        }
                    }
                    .oneToSix-item1:nth-child(6){
                        span:nth-child(1){
                            /*width: 96px;*/
                            margin-left: -27px;
                        }
                    }
                    .oneToSix-item2{
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        /*justify-content: space-between;*/
                        .item2-input{
                          ::v-deep .item-input{
                                width: 60px;
                                /*display: flex;*/
                            }
                        }
                    }
                }
            }
            .resourcesNiche{
              box-sizing: border-box;
              padding-left: 30px;
                .time-title{
                    color: #13131B;
                    font-size: 14px;
                    padding-top: 24px;
                    display: flex;
                    justify-content:space-around;
                    .titleSpan:nth-child(1){
                        margin-left: 36px;
                    }
                    .titleSpan:nth-child(2){
                        margin-left: 30px;
                    }
                    .titleSpan:nth-child(3){
                        margin-left: 20px;
                    }
                }

                .oneToSix{
                    margin-top: 18px;
                    .oneToSix-item1:nth-child(1){
                        margin-top: 0px;
                        span:nth-child(1){
                            margin-left: -60px;
                        }
                    }
                    .oneToSix-item1:nth-child(2){
                        span:nth-child(1){
                            margin-left: -60px;
                        }
                    }
                    .oneToSix-item1:nth-child(3){
                        span:nth-child(1){
                            margin-left: -51px;
                        }
                    }
                    .oneToSix-item1:nth-child(4){
                        span:nth-child(1){
                            margin-left: -36px;
                        }
                    }
                    .oneToSix-item1:nth-child(5){
                        span:nth-child(1){
                            margin-left: -22px;
                        }
                    }
                    .oneToSix-item1{
                        margin-top: 18px;
                        display: flex;
                        /*justify-content: space-between;*/
                        align-items: center;
                      ::v-deep .item-input{
                            width: 160px;
                        }
                        span:nth-child(1){
                            display: inline-block;
                            /*width: 110px;*/
                        }
                    }
                    .oneToSix-item2{
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        /*justify-content: space-between;*/
                        .item2-input{
                          ::v-deep .item-input{
                                width: 60px;
                                /*display: flex;*/
                            }
                        }
                    }
                }
            }
            .resourcesNiche2{
                width: 758px;
                padding-left: 47px;
                .time-title{
                    color: #13131B;
                    font-size: 14px;
                    padding-top: 24px;
                    display: flex;
                    justify-content:space-around;
                    .titleSpan:nth-child(1){
                        margin-left: 10px;
                    }
                    .titleSpan:nth-child(2){
                        margin-right: 10px;
                    }
                    .titleSpan:nth-child(3){
                        margin-right: 28px;
                    }
                }

                .oneToSix{
                    margin-top: 18px;
                    .oneToSix-item1:nth-child(1){
                        margin-top: 0px;
                        span:nth-child(1){
                            margin-left: -28px;
                        }
                    }
                    .oneToSix-item1:nth-child(2){
                        span:nth-child(1){
                            margin-left: -30px;
                        }
                    }
                    .oneToSix-item1:nth-child(3){
                        span:nth-child(1){
                            margin-left: -42px;
                        }
                    }
                    .oneToSix-item1:nth-child(4){
                        span:nth-child(1){
                            margin-left: -60px;
                        }
                    }
                    .oneToSix-item1:nth-child(5){
                        span:nth-child(1){
                            margin-left: -46px;
                        }
                    }
                    .oneToSix-item1{
                        margin-top: 18px;
                        display: flex;
                        /*justify-content: space-between;*/
                        align-items: center;
                      ::v-deep .item-input{
                            width: 160px;
                        }
                        span:nth-child(1){
                            display: inline-block;
                        }
                    }
                    .oneToSix-item2{
                        margin-top: 10px;
                        display: flex;
                        align-items: center;
                        /*justify-content: space-between;*/
                        .item2-input{
                          ::v-deep .item-input{
                                width: 60px;
                                /*display: flex;*/
                            }
                        }
                    }
                }
            }
            .stepFour{
                .bidSet{
                    padding-left: 24px;
                    display: flex;
                    .bidSetTxt{
                        padding-left: 8px;
                        text-align: right;
                        display: flex;
                        flex-direction: column;
                        padding-top: 72px;
                        span:nth-child(2){
                            padding-top: 34px;
                        }
                        margin-right: 10px;
                    }
                    .bidSetItem:nth-child(2){
                        margin-left: 0;
                    }
                    .bidSetItem{
                        margin-left: 80px;
                        display: flex;
                        flex-direction: column;
                        span:nth-child(1){
                            text-align: center;
                            padding-top: 28px;
                        }
                        span:nth-child(2){
                            padding-top: 20px;
                        }
                        span:nth-child(3){
                            padding-top: 10px;
                        }
                    }
                }
            }
            .creationSucceed{
                margin-top: 120px;
                display: flex;
                flex-direction: column;
                align-items: center;
                i{
                    font-size: 52px;
                    color: #20D08C;
                }
                span{
                    color: #14141C;
                    font-size: 24px;
                }
            }
        }
        .footerBtn{
            display: flex;
            justify-content: center;
            padding-top: 136px;
            padding-bottom: 70px;
            .btn{
                width: 100px;
                height: 30px;
                line-height: 0;
                border-color: #1122D8;
            }
            .btn:nth-child(1){
                background: #ffffff;
                color: #1122D8;
            }
            .btn:nth-child(2){
                background: #1122D8;
                color: #fff;
            }
        }
    }
}
